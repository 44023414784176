import _ from "lodash";
import postalCodeChecker from "../../utils/postalCodeChecker";

const defaultAttributesByCountry = {
  canada: {
    province: {
      col: "6",
      required: true,
      display: true,
      defaultValue: "",
      type: "text",
      label: "Province",
    },
  },
  denmark: {
    province: {
      col: "6",
      required: false,
      display: true,
      defaultValue: "",
      type: "text",
      label: "Province",
    },
  },
};

const checkMissingAddressInfo = (address) => {
  return {
    postalCode:
      _.isEmpty(address.postalCode) ||
      !postalCodeChecker(address.postalCode, address.country),
    address_1: _.isEmpty(address.address_1),
    lat: _.isEmpty(address.lat),
    long: _.isEmpty(address.long),
  };
};

const isAddressValid = (address) => {
  if (_.isEmpty(address)) return false;

  return !_.some(_.values(checkMissingAddressInfo(address)));
};

export { defaultAttributesByCountry, checkMissingAddressInfo, isAddressValid };
