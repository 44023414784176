import React, { useContext } from "react";
import { BreakpointContext } from "../../context";

const BetterRestoLogo = ({ fontSize }) => {
  const breakpoints = useContext(BreakpointContext);
  return (
    <>
      <span style={{ color: "#ff5004", fontSize: `${fontSize || 30}px` }}>
        {breakpoints.md ? "B" : "Better"}
      </span>
      <span style={{ color: "#1c1c1c", fontSize: `${fontSize || 30}px` }}>
        {breakpoints.md ? "R" : " Resto"}
      </span>
    </>
  );
};

export default BetterRestoLogo;
