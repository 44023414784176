import React, { useContext, useState } from "react";
import { usePriceFormat } from "../../hooks";
import { CartContext } from "../../context";

import CartModal from "../../components/modals/CartModal";
import { useTrans } from "../../hooks";

// define a cart checkout component
// that shows a cart and allows the user to view and edit the its contents
const CartCheckout = () => {
  // use the translation hook to get the translation function
  const transMsg = useTrans();

  // use the cart context to get the cart totals
  const { cartTotals } = useContext(CartContext);
  // define a state variable to determine if the cart modal should be shown
  // the showCart state variable is toggled when the user clicks on the cart icon
  // the cart icon is only shown when the cart has items in it
  const [showCart, setShowCart] = useState(false);
  // define functions to toggle the showCart state variable
  const handleClose = () => setShowCart(false);
  const handleShow = () => setShowCart(true);

  // use the usePriceFormat hook to format the price
  const { priceFormat } = usePriceFormat();

  // ================================================================
  // return a div el that contains the cart icon and the cart modal
  // that is shown or hidden based on the showCart state variable
  // when the modal is closed, the handleClose function is called to update the showCart state variable
  // when the cart icon is clicked, the handleShow function is called to update the showCart state variable
  return (
    <>
      {cartTotals && cartTotals.qty > 0 && (
        <div
          className="generator-bgOFF rounded shadow-sm mb-4OFF p-4 osahan-cart-item cart-fix noselect"
          onClick={handleShow}
          role="button"
          tabIndex={0}
          onKeyPress={() => {}}
        >
          <p className="mb-1 text-black font-24 text-center bold">
            {transMsg("viewBag")} <i className="fa bag-icon">&#xf290;</i>
            <span className="badge badge-danger round" id="lblCartCount">
              {" "}
              {cartTotals.qty}
            </span>
            <span> &#8226; </span>
            <span className="bold">{priceFormat(cartTotals?.subtotal)}</span>
          </p>
        </div>
      )}

      <CartModal show={showCart} onHide={handleClose} />
    </>
  );
};

export default CartCheckout;
