import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { LocationsContext, RestaurantContext } from "../../context";
import _ from "lodash";
import { useTrans } from "../../hooks";

const MultiLocationModal = () => {
  const {
    selectedLocation,
    setSelectedLocation,
    locations,
    isOpenMultiLocationModal,
    setIsOpenMultiLocationModal,
  } = useContext(LocationsContext);
  const transMsg = useTrans();

  const { restaurant } = useContext(RestaurantContext);
  const onHide = () => {
    setIsOpenMultiLocationModal(!isOpenMultiLocationModal);
  };

  return (
    <Modal show={isOpenMultiLocationModal} onHide={onHide} centered>
      <Modal.Header closeButton={true}>
        <div className=" h5 ">
          {transMsg("select")} <span className="text-muted"> {restaurant?.name} </span>
          {transMsg("location")}
        </div>
      </Modal.Header>
      <Modal.Body>
        {!_.isEmpty(locations) && _.isArray(locations) && (
          <>
            <div className="row">
              <div className="col-12 ">
                <select
                  className="custom-select w-100"
                  id="selectLocation"
                  value={selectedLocation?.id}
                  onChange={({ target: { value } }) => {
                    const location = locations.find((l) => l.id === value);
                    if (location) {
                      setSelectedLocation(location);
                    }
                    onHide();
                  }}
                >
                  {locations.map((l, i) => {
                    return (
                      <React.Fragment key={l.id}>
                        <option value={l.id}>
                          {`${l.name?.split("(")?.[1]?.replace(")", "")} - ${
                            l.address.address_1
                          }`}
                        </option>
                      </React.Fragment>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default MultiLocationModal;
