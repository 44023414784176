import React, { useContext, useEffect } from "react";
import { CartContext } from "../../context";
import _ from "lodash";
import { useAlert } from "react-alert";
import { useTrans } from "../../hooks";

const CannotDeliverMessage = ({ showAlert }) => {
  const transMsg = useTrans();
  const { canDeliver, orderType, shippingAddress } = useContext(CartContext);
  const alert = useAlert();

  useEffect(() => {
    if (_.isUndefined(showAlert)) return;
    if (orderType !== "delivery") return;
    if (canDeliver) return;
    // check if the canDeliver is null then we don't show the message
    // since null means it doesn't have a value yet
    if (_.isNull(canDeliver)) return;

    alert.error(transMsg("cannotDeliverMessage"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlert, orderType, canDeliver]);
  return (
    <>
      {/* 
      // WE are not doing !canDeliver here because null is a valid value for canDeliver
      // and we don't want to show the message if the value is null
      // so we are checking if the value is false specifically
    */}
      {orderType === "delivery" && canDeliver === false && (
        <div className="text-danger h4">{transMsg("cannotDeliverMessage")}</div>
      )}
    </>
  );
};

export default CannotDeliverMessage;
