import React, { useEffect, useContext, useMemo, useState } from "react";

import { CartContext } from "../../context";
import _ from "lodash";
import { useBusinessIsOpen } from "../../hooks";
import moment from "moment";

const ScheduleTime = ({
  optionsNum = 10,
  hoursType = "businessHours",
  label = "Pickup time",
} = {}) => {
  const cartContext = useContext(CartContext);

  const [optionValue, setOptionValue] = useState(0);
  const hoursTypeAuto = useMemo(() => {
    if (hoursType !== "auto") return hoursType;
    if (_.isEmpty(cartContext.orderType)) return "businessHours";
    return cartContext.orderType;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursType, cartContext.orderType]);

  const { dateNow, nextDate, isOpen } = useBusinessIsOpen({
    hoursType: hoursTypeAuto,
  });
  // =========================================================

  const pushTime = (obj) => {
    // console.log(obj);
    let gap = cartContext.orderType === "pickup" ? 15 : 30;

    let startAt = moment(obj.time).add(gap * obj.i, "m");

    if (startAt > obj.closeTime || endAt > obj.closeTime) return;
    // startAt.subtract(startAt % 30, "m");
    let endAt = moment(startAt).add(gap, "m");
    // endAt = endAt > obj.closeTime ? obj.closeTime : endAt;
    // endAt.subtract(endAt % 30, "m");

    if (
      new Date().getDay() + 1 == startAt.format("d") ||
      new Date().getDay() == startAt.format("d")
    ) {
      let day = "Today";
      if (new Date().getDay() + 1 == startAt.format("d")) day = "Tomorrow";
      return {
        display: `${day} ${startAt.format("h:mm a")} - ${endAt.format("h:mm a")}`,
        from: startAt,
        to: endAt,
      };
    } else {
      return {
        display: `${startAt.format("ddd h:mm a")} - ${endAt.format("h:mm a")}`,
        from: startAt,
        to: endAt,
      };
    }

    return null;
  };

  const options = useMemo(() => {
    if (_.isEmpty(dateNow) || _.isEmpty(nextDate)) return;
    let _opts = [];

    let now = isOpen ? moment().add(90, "m") : dateNow.openTime;
    // round to nearest quarter
    now.minutes(_.floor(now.minutes() / 30));

    if (isOpen)
      _opts.push({
        display: "ASAP",
        from: moment(now),
        to: moment(now).add(30, "m"),
      });

    _.range(optionsNum).forEach((i) => {
      const res = pushTime({
        time: moment(now).add(30, "m"),
        closeTime: dateNow.closeTime,
        i,
      });
      if (res) _opts.push(res);
    });

    // if options number less than options length add values fron next dates
    if (_opts.length < optionsNum) {
      _.range(optionsNum - _opts.length).forEach((i) => {
        const res = pushTime({
          time: nextDate.openTime,
          closeTime: nextDate.closeTime,
          i,
        });
        if (res) _opts.push(res);
      });
    }

    // console.log("_opts", _opts);
    return _opts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateNow, nextDate, isOpen]);

  useEffect(() => {
    if (_.isEmpty(options)) return;
    const scheduleTimeIndex = _.findIndex(
      options,
      (o) => o.display === cartContext?.scheduleTime?.display,
    );
    if (scheduleTimeIndex < 0) {
      cartContext.setScheduleTime(options[0]);
    } else setOptionValue(scheduleTimeIndex);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  return (
    <>
      <div className="form-inline pb-4">
        <label className="my-1 mr-sm-2 mb-2 h6-text" htmlFor="inlineFormInputSelectTime">
          <i
            className="icofont-clock-time icofont-1x mr-1"
            style={{ color: "rgb(41, 41, 41)" }}
          ></i>
          {label}:
        </label>
        {!_.isEmpty(options) && (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            className="custom-select w-100 time-select"
            id="inlineFormInputSelectTime"
            value={optionValue}
            onChange={({ target: { value } }) => {
              cartContext.setScheduleTime({
                ...options[value],
              });
              setOptionValue(value);
            }}
          >
            {options.map((o, i) => {
              return (
                <React.Fragment key={i}>
                  <option value={i}>{o.display}</option>
                </React.Fragment>
              );
            })}
          </select>
        )}
      </div>
    </>
  );
};

export default ScheduleTime;
