import React, { useEffect, useMemo, useState, useContext } from "react";
import { Form, ListGroup } from "react-bootstrap";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import produce from "immer";
import { GoogleMap, Marker } from "@react-google-maps/api";
import formatAddress from "../../utils/formatAddress";
import _ from "lodash";
import { useTrans } from "../../hooks";
import AddressInput from "./index";
import { LocationsContext } from "../../context";
import useOnclickOutside from "react-cool-onclickoutside";

const NewAutoAddressInput = ({ uniqueKey, address, onDone, disabled }) => {
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(address);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [error, setError] = useState(null);

  const locationsContext = useContext(LocationsContext);

  useEffect(() => {
    onDone(selectedAddress);
  }, [selectedAddress]);

  const onSelect = (suggestion) => {
    const { description, place_id } = suggestion;

    const createAddressObject = (details) => {
      let _address = { address_1: "", postalCode: "" };

      const addressMap = new Map([
        [
          "street_number",
          (component) => (_address.address_1 = component["short_name"] + " "),
        ],
        ["route", (component) => (_address.address_1 += component["long_name"])],
        [
          "administrative_area_level_1",
          (component) => (_address.province = component["long_name"]),
        ],
        ["country", (component) => (_address.country = component["long_name"])],
        ["postal_code", (component) => (_address.postalCode = component["short_name"])],
        ["locality", (component) => (_address.city = component["long_name"])],
      ]);

      for (const component of details.address_components) {
        if (addressMap.has(component.types[0])) {
          addressMap.get(component.types[0])(component);
        }
      }

      _address.lat = _.toString(details.geometry.location.lat());
      _address.long = _.toString(details.geometry.location.lng());
      _address.google_address = description;

      setIsAddressSelected(true);
      return _address;
    };

    const parameter = {
      placeId: place_id,
      fields: ["address_components", "geometry"],
    };

    getDetails(parameter)
      .then((details) => {
        let _address = createAddressObject(details);
        console.log("Created Address from Google API for the selected address", _address);

        if (uniqueKey === "BillingAddressPickup") {
          setSelectedAddress(_address);
        } else {
          console.log("newAutocomplete -> getDetails -> getDistanceMatrixToLocations");

          locationsContext
            .getDistanceMatrixToLocations(
              { lat: parseFloat(_address.lat), lng: parseFloat(_address.long) },
              locationsContext.locations,
            )
            .then((distanceMatrixToLocations) => {
              const closestLocation = locationsContext.getClosestLocation(
                distanceMatrixToLocations,
                locationsContext.locations,
              );
              locationsContext.setSelectedLocation(closestLocation);
            });

          const _selectedAddress = produce(selectedAddress, (draft) => {
            _.map(_address, (v, k) => {
              draft[k] = v;
            });
          });
          setSelectedAddress(_selectedAddress);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  useEffect(() => {
    if (hasInteracted && !isAddressSelected) {
      setError("Please select an address from the dropdown.");
    } else {
      setError(null);
    }
  }, [hasInteracted, isAddressSelected]);

  return (
    <>
      <SelectAddress
        uniqueKey={uniqueKey}
        selectedAddress={selectedAddress}
        onSelect={onSelect}
        disabled={disabled}
        required={true}
        hasInteracted={hasInteracted}
        setHasInteracted={setHasInteracted}
        error={error}
      />

      {(isAddressSelected || !_.isUndefined(selectedAddress.lat)) && (
        <>
          <Address2
            uniqueKey={uniqueKey}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
          />
          <div className="mb-4"></div>
          <LatLongMap
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            onDone={onDone}
          />
        </>
      )}
    </>
  );
};

const SelectAddress = ({
  uniqueKey,
  selectedAddress,
  onSelect,
  disabled,
  required,
  hasInteracted,
  setHasInteracted,
  error,
}) => {
  const transMsg = useTrans();
  const locationsContext = useContext(LocationsContext);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
      componentRestrictions: {
        country:
          _.lowerCase(locationsContext?.selectedLocation?.address?.countryCode) || "ca",
      },
      location: new window.google.maps.LatLng(
        parseFloat(locationsContext?.selectedLocation?.address?.lat),
        parseFloat(locationsContext?.selectedLocation?.address?.long),
      ),
      radius: 100000,
    },
    callbackName: "initMap",
    debounce: 400,
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  useEffect(() => {
    if (_.isEmpty(selectedAddress.lat)) return;
    setValue(formatAddress(selectedAddress, true, true), false);
  }, [selectedAddress]);

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListGroup.Item
          key={place_id}
          style={{ cursor: "pointer" }}
          onClick={async () => {
            onSelect(suggestion);
            clearSuggestions();
          }}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>{" "}
        </ListGroup.Item>
      );
    });

  return (
    <>
      <div className="row" ref={ref}>
        <div className={`col-md-12`}>
          <div className="form-label-group">
            <Form.Control
              as="input"
              type="text"
              id={`address_${uniqueKey}`}
              placeholder=""
              className="require"
              name="address"
              onChange={({ target: { value } }) => {
                if (ready) {
                  setValue(value, value.length > 2);
                  setHasInteracted(true);
                }
              }}
              required
              value={value}
              autoComplete="off"
            />

            <Form.Label htmlFor={`address_${uniqueKey}`}>
              {transMsg("pleaseEnterAddress")}{" "}
              {_.isEmpty(selectedAddress?.lat) && (
                <span className="text-danger pr-2">*</span>
              )}
            </Form.Label>
          </div>
          {status === "OK" && (
            <ListGroup style={{ position: "absolute", zIndex: 1 }}>
              {renderSuggestions()}
            </ListGroup>
          )}
        </div>
      </div>
      {error && <div className="text-danger">{error}</div>}
    </>
  );
};

const Address2 = ({ uniqueKey, selectedAddress, setSelectedAddress }) => {
  return (
    <>
      <AddressInput
        uniqueKey={uniqueKey}
        address={selectedAddress}
        setAddress={setSelectedAddress}
        fieldAttributes={{
          phone: { display: false },
          firstName: { display: false },
          lastName: { display: false },
          country: { display: false },
          city: { display: false },
          address_1: { display: false, require: false },
          address_2: { display: true, require: false, col: "6" },
          postalCode: { display: false, require: false },
          province: { display: false },
        }}
      />
    </>
  );
};

const LatLongMap = ({ selectedAddress, setSelectedAddress, onDone }) => {
  const mapCenter = useMemo(() => {
    return {
      lat: selectedAddress?.lat ? _.toNumber(selectedAddress?.lat) : -122.0841,
      lng: selectedAddress?.long ? _.toNumber(selectedAddress?.long) : 37.4221,
    };
  }, [selectedAddress]);
  return (
    <>
      {!_.isEmpty(selectedAddress?.lat) && (
        <>
          <div className="row">
            <div className="col-12" style={{ height: 200 }}>
              <GoogleMap
                mapContainerStyle={{
                  height: "200px",
                }}
                options={{
                  clickableIcons: false,
                  gestureHandling: "none",
                  fullscreenControl: false,
                  mapTypeControl: false,
                  streetViewControl: false,
                  zoomControl: true,
                }}
                center={mapCenter}
                zoom={18}
              >
                <Marker
                  position={mapCenter}
                  draggable={true}
                  onDragEnd={(e) => {
                    const _address = produce(selectedAddress, (draft) => {
                      draft.lat = _.toString(e.latLng.lat());
                      draft.long = _.toString(e.latLng.lng());
                    });
                    setSelectedAddress(_address);
                  }}
                />
              </GoogleMap>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NewAutoAddressInput;
