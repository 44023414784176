import React, { useContext } from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { Container } from "react-bootstrap";
import { CartContext } from "../../context";
import LocalesComponent from "../LocalesComponent";
import { useTrans, useLocalization } from "../../hooks";
import BetterRestoLogo from "../BetterRestoLogo";
import _ from "lodash";

const Footer = ({ pages, showCart }) => {
  const cartContext = useContext(CartContext);
  const transMsg = useTrans();
  const { locale: currentLocale } = useLocalization();

  return (
    <>
      <footer
        className=" footer"
        style={
          cartContext?.cartTotals?.qty > 0 && showCart
            ? { marginBottom: "50px" }
            : { marginBottom: "0px" }
        }
      >
        <Container className="pb-5 pt-3 px-5">
          {_.isArray(pages) && pages.length > 2 && (
            <>
              <div className="row pb-3">
                <div className="col-12">
                  <ul className="list-inline">
                    {pages.map((v, i) => {
                      if (_.indexOf(["terms", "privacy"], v.handle) > -1)
                        return <React.Fragment key={i}></React.Fragment>;
                      else
                        return (
                          <React.Fragment key={i}>
                            <Link
                              to={`/${v.handle}`}
                              className="text-dark pr-2"
                              style={{ fontSize: 16 }}
                            >
                              {v[currentLocale].title}
                            </Link>
                          </React.Fragment>
                        );
                    })}
                    <li className="list-inline-item"></li>
                  </ul>
                </div>
              </div>
              <hr />
            </>
          )}

          <div className="row">
            <div className="col-md-6 order-2 pt-3 text-center text-md-left pt-md-0 order-md-1">
              {transMsg("poweredBy")}
              <a
                rel="noreferrer"
                className="ml-1 theme-color-text"
                target="_blank"
                href="https://www.betterresto.com"
              >
                <BetterRestoLogo fontSize={14} />
              </a>
            </div>
            <div className="col-md-6 text-md-right  text-center  order-md-2">
              <Link to="/terms" className="text-grey mr-2">
                {transMsg("termsOfUse")}
              </Link>
              <Link to="/privacy" className="text-grey mr-2">
                {transMsg("privacyPolicy")}
              </Link>
              <LocalesComponent
                linkClassName="text-uppercase p-2OFF"
                textClassName="text-grey"
              />
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
