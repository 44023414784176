import React, { useMemo } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import _ from "lodash";
import Icofont from "../IcoFont";
import { useLocalization } from "../../hooks";

const LocalesComponent = ({
  displayShort = true,
  showIcone = false,
  linkClassName = "",
  textClassName = "",
}) => {
  const { locale, defaultLang, config, locales } = useLocalization();

  const { pathname, state } = useLocation();

  const showList = useMemo(() => {
    return _.split(locales, " ").length > 2;
  }, [locales]);

  const displayKey = useMemo(() => {
    if (displayShort) return "code";
    return "localName";
  }, [displayShort]);

  const linkPath = useMemo(() => {
    if (locale !== defaultLang) {
      return _.join(_.drop(_.split(pathname, "/"), 2), "/");
    }
    return _.trimStart(pathname, "/");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const localesData = useMemo(() => {
    if (_.isEmpty(config)) return;

    let res = {};
    config.forEach((v) => {
      if (_.includes(locales, v.code))
        res[v.code] = {
          linkTo: `/${defaultLang === v.code ? linkPath : `${v.code}/${linkPath}`}`,
          code: v.code,

          localName: v.localName,
        };
    });
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, defaultLang, linkPath, locale]);

  const displayLocale = useMemo(() => {
    if (_.isEmpty(locale)) return;
    if (showList) return locale;

    return _.find(_.keys(localesData), (k) => k !== locale);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, showList]);

  return (
    <>
      {localesData && showList ? (
        <></>
      ) : (
        <>
          {localesData?.[displayLocale] && (
            <Link
              className={linkClassName}
              to={localesData[displayLocale]?.linkTo}
              state={state}
            >
              {showIcone && <Icofont className="mr-3 icon-size" icon="globe" />}

              <span className={textClassName}>
                {localesData[displayLocale]?.[displayKey]}
              </span>
            </Link>
          )}
        </>
      )}
    </>
  );
};

export default LocalesComponent;
