import React, { useEffect, useContext, useState } from "react";
import { Button, Modal, Form, Spinner } from "react-bootstrap";

import { CartContext, LocationsContext } from "../../context";
import _ from "lodash";

import ScheduleTime from "../ScheduleTime";
import CannotDeliverMessage from "../CannotDeliverMessage";
import { useCreateCart, useUpdateCart } from "../../hooks";
import NewAutoAddressInput from "../AddressInput/newAutocomplete";
import { useTrans, useUpdateLocationBasedOnPostalCode } from "../../hooks";
import produce from "immer";

const DeliveryAddressModal = ({ show, onHide }) => {
  // get the translation hook
  const transMsg = useTrans();

  // get the cart context and the locations context
  const cartContext = useContext(CartContext);
  const locationsContext = useContext(LocationsContext);

  // get the create cart and update cart hooks
  const { created: cartCreated, onCreate: onCreateCart } = useCreateCart();
  const { updated: cartUpdated, onUpdate: updateCart } = useUpdateCart();

  // create a state to check if the user has clicked on the done button
  const [checkFeeNow, setCheckFeeNow] = useState(false);
  const updateSelectedLocation = useUpdateLocationBasedOnPostalCode();

  // =========================================================
  // This effect will run when the locationsContext is changed
  // it's purpose is to update the shipping address in the cart context
  useEffect(() => {
    // if the user has not selected a location, we don't need to ask him about delivery address
    if (_.isEmpty(locationsContext)) return;
    if (_.isEmpty(locationsContext.selectedLocation)) return;

    // create a variable to populate the shipping address in the cart context
    const populateAddress = {
      countryCode: locationsContext.selectedLocation.address.countryCode,
      country: locationsContext.selectedLocation.address.country,
      city: locationsContext.selectedLocation.address.city,
      province: locationsContext.selectedLocation.address.province,
    };

    // if the cartContext has a shipping address then we don't need to populate it???
    // if the cartContext has no shipping address,
    // we will populate it with the selected location
    if (_.isEmpty(cartContext.shippingAddress)) {
      cartContext.setShippingAddress({
        ...cartContext.shippingAddress,
        ...populateAddress,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsContext]);

  // =========================================================
  // This effect will run when the checkFeeNow state is changed to true (which is when the user clicks on the done button)
  // and the order type is delivery
  // it's purpose is to create a cart or update the cart
  useEffect(() => {
    // if check delivery fee is not true, we don't need to do anything
    // if the order type is not delivery, we don't need to do anything
    if (checkFeeNow !== true) return;
    if (cartContext.orderType !== "delivery") return;

    // if the cart token is empty, we will create a cart
    if (_.isEmpty(cartContext.cartToken)) onCreateCart();
    // if the cart token is not empty, we will update the cart
    else updateCart("ADDRESS");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFeeNow]);

  // =========================================================
  // This effect will run when the cart is created or updated
  // it's purpose is to check if the cart can deliver
  useEffect(() => {
    // we will set the checkFeeNow to false
    setCheckFeeNow(false);
    // if the cart is not created or updated, we don't need to do anything
    if (cartCreated === false && cartUpdated === false) return;

    // if the cart can deliver, we will hide the modal
    // this means that the cart already has a delivery fee and we don't need to ask the user about delivery address
    if (cartContext.canDeliver) onHide();
  }, [cartCreated, cartUpdated]);

  // =========================================================
  // This function will run when the user clicks on the done button
  const onSubmit = (e) => {
    // prevent the default action of the form submit button (which is to refresh the page)
    e.preventDefault();
    // check if the selected location has delivery options and update the selected location based on the postal code of the shipping address
    updateSelectedLocation();

    // if the selected location has delivery options, we will update the selected location
    // based on the postal code of the shipping address, with the price of the restaurant own delivery fee
    // if (!_.isEmpty(locationsContext.selectedLocation.deliveryOptionsJson))
    // updateSelectedLocation();

    // we will set the checkFeeNow to true, which will trigger the useEffect above
    setCheckFeeNow(true);
  };

  // =========================================================
  // render the delivery address modal that will ask the user about his delivery address
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton={true}>
          <Modal.Title>
            {transMsg("deliveryDetails")}{" "}
            {checkFeeNow && <Spinner animation="border" variant="info" />}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CannotDeliverMessage />

          <Form onSubmit={onSubmit}>
            <>
              <NewAutoAddressInput
                uniqueKey="DeliveryAddressModal"
                // the address is the shipping address in the cart context (which is empty at first)
                address={cartContext.shippingAddress}
                // if it's checkFeeNow, then the user can't edit the address anymore because we are checking the delivery fee
                disabled={checkFeeNow}
                onDone={(address) => {
                  cartContext.setShippingAddress(
                    produce(cartContext.shippingAddress, (draft) => {
                      _.map(address, (v, k) => {
                        draft[k] = v;
                      });
                    }),
                  );
                }}
              />
            </>

            <div className="pt-5"></div>
            <ScheduleTime hoursType="delivery" label={transMsg("deliveryTime")} />

            <Button
              variant="secondary"
              type="submit"
              className="btn-height btn btn-lg btn-outline-primaryOFF btn-block text-uppercaseOFF font-weight-bold  round-btn theme-color-text theme-color-back"
              disabled={_.isEmpty(cartContext.shippingAddress?.address_1)}
            >
              {transMsg("done")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeliveryAddressModal;
