/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Header from "./Header";
import Footer from "./Footer";
import Seo from "../seo";
import Menu from "../Menu";
import MenuProvider from "../react-flexible-sliding-menu";
import classnames from "classnames";
import MaintenanceModal from "../modals/MaintenanceModal";
import ExternalDeliveryModal from "../modals/ExternalDeliveryModal";
import { RestaurantContext } from "../../context";
import MultiLocationModal from "../modals/MultiLocationModal";
import CartCheckout from "../CartCheckout";

const Layout = ({
  seo,
  children,
  showCart,
  showBottomCart,
  fixedHeader,
  showOrderType,
  hideExternalDeliveryModal,
  hasMultipleShareLocations,
  restaurant,
  showLocationInfo,
  pages,
}) => {
  const [loading, setLoading] = useState(false);

  const { setRestaurant } = useContext(RestaurantContext);
  useEffect(() => {
    setTimeout(() => setLoading(true), 150);
  }, []);

  useEffect(() => {
    if (_.isEmpty(restaurant)) return;

    setRestaurant(restaurant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant]);

  return (
    <MenuProvider MenuComponent={Menu}>
      <div>
        <Seo {...seo} />

        <div className={classnames("fade", { show: loading })}>
          <Header
            showCart={showCart}
            showOrderType={showOrderType}
            fixedHeader={fixedHeader}
            showShareLocationsOnMenu={hasMultipleShareLocations}
            showLocationInfo={showLocationInfo}
          />
          <div>
            <main>
              <MaintenanceModal />
              <MultiLocationModal />
              {hideExternalDeliveryModal ? <></> : <ExternalDeliveryModal />}

              {children}
            </main>
          </div>
          {showBottomCart && <CartCheckout />}
          <Footer showCart={showBottomCart} pages={pages} />
        </div>
      </div>
    </MenuProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
