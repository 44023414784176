import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { LocationsContext } from "../../context";
import ScheduleTime from "../ScheduleTime";
import { useTrans } from "../../hooks";

const PickupAddressModal = ({ show, onHide }) => {
  const transMsg = useTrans();
  const locationsContext = useContext(LocationsContext);

  // =========================================================
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title> {transMsg("pickupDetails")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="h6-text">
            <i
              className="icofont-location-pin icofont-1x mr-1"
              style={{ color: "rgb(41, 41, 41)" }}
            ></i>
            {locationsContext?.selectedLocation?.name}
            {locationsContext?.selectedLocation?.address?.address_1},{" "}
            {locationsContext?.selectedLocation?.address?.city}
          </p>
          <ScheduleTime hoursType="pickup" label={transMsg("pickupTime")} />
          <Button variant="secondary" onClick={onHide} className="modal-delivery-btn">
            {transMsg("done")}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PickupAddressModal;
