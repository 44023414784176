import React, { useState, useContext } from "react";
import { Container, Button } from "react-bootstrap";
import Icofont from "../IcoFont";
import { CartContext, ModalContext, RestaurantContext } from "../../context";
import _ from "lodash";
import { LocationsContext } from "../../context";
import { MenuContext } from "../react-flexible-sliding-menu";
import DeliveryAddressModal from "../modals/DeliveryAddressModal";
import PickupAddressModal from "../modals/PickupAddressModal";
import CartModal from "../modals/CartModal";
import classnames from "classnames";
import StrapiImage, { STRAPI_IMAGE_SIZES } from "../StrapiImage";
import { useNavigate } from "../../hooks";
import { useTrans } from "../../hooks";

const Header = ({
  showCart,
  showOrderType,
  fixedHeader,
  showShareLocationsOnMenu,
  showLocationInfo,
}) => {
  const transMsg = useTrans();
  const locationsContext = useContext(LocationsContext);
  const cartContext = useContext(CartContext);
  const { restaurant } = useContext(RestaurantContext);
  const { showDeliveryModal, setShowDeliveryModal } = useContext(ModalContext);

  const { toggleMenu, setMenuProps } = useContext(MenuContext);
  const navigate = useNavigate();
  const [showCartModal, setShowCartModal] = useState(false);
  const handleClose = () => setShowCartModal(false);
  const handleShow = () => setShowCartModal(true);

  const handleCloseDelivery = () => setShowDeliveryModal(false);
  const handleShowDelivery = () => setShowDeliveryModal(true);

  const [showPickup, setShowPickup] = useState(false);
  const handleClosePickup = () => setShowPickup(false);
  const handleShowPickup = () => setShowPickup(true);

  // =========================================================
  return (
    <>
      {/* MOD AK WAS CONTAINER */}
      <Container
        className={classnames({ "fixed-top": fixedHeader })}
        style={{ minHeight: 58, backgroundColor: "#fff" }}
      >
        <div className="row ">
          <div className="col-12 py-2">
            <ul className="nav align-items-center">
              <li className="nav-item mr-auto mr-md-2 order-1">
                <Button
                  variant="link"
                  style={{ color: "#000" }}
                  onClick={() => {
                    setMenuProps({
                      showShareLocations: showShareLocationsOnMenu,
                    });
                    toggleMenu();
                  }}
                >
                  <Icofont icon="navigation-menu" size={2} />
                </Button>
              </li>

              {showOrderType && (
                <>
                  {cartContext.orderType === "pickup" && (
                    <li className="nav-item ml-auto ml-md-2 mr-md-auto order-4 order-md-3">
                      <Button className="nav-delivery-btn" onClick={handleShowPickup}>
                        <i className="fa fa-cutlery mr-2" aria-hidden="true"></i>
                        <span style={{ fontSize: "14px", color: "#424242" }}>
                          {transMsg("pickupAt")}{" "}
                        </span>
                        {locationsContext.selectedLocation?.address?.address_1},{" "}
                        {locationsContext.selectedLocation?.address?.city} &#8226;{" "}
                        {cartContext.scheduleTime?.display || ""}
                      </Button>
                    </li>
                  )}
                  {cartContext.orderType === "delivery" && (
                    <li className="nav-item ml-auto ml-md-2 mr-md-auto order-4 order-md-3">
                      <Button className="nav-delivery-btn" onClick={handleShowDelivery}>
                        <i className="fa fa-map-marker mr-2" aria-hidden="true"></i>
                        <span style={{ fontSize: "14px", color: "#424242" }}>
                          {_.isEmpty(cartContext.shippingAddress?.address_1)
                            ? transMsg("pleaseEnterAddress")
                            : transMsg("deliverTo")}
                        </span>{" "}
                        {cartContext.shippingAddress?.address_1} &#8226;{" "}
                        {cartContext.scheduleTime?.display || ""}
                      </Button>
                    </li>
                  )}
                </>
              )}

              {showCart && cartContext.cartTotals?.qty > 0 && (
                <React.Fragment>
                  <li className="nav-item d-md-inline ml-auto d-none order-2 order-md-4">
                    <Button className="nav-cart-btn" onClick={handleShow}>
                      <i
                        className="fa bag-icon mr-3"
                        style={{ color: "white", fontSize: "18px" }}
                      >
                        &#xf290;
                      </i>
                      {cartContext.cartTotals.qty}
                    </Button>
                  </li>
                </React.Fragment>
              )}

              {showLocationInfo && (
                <>
                  <li className="nav-item  order-2 m-auto">
                    <div
                      className="media"
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        navigate("/");
                      }}
                      onKeyPress={() => {}}
                    >
                      <StrapiImage
                        className="rounded-pill mr-3"
                        size={STRAPI_IMAGE_SIZES.THUMBNAIL}
                        alt={restaurant?.logo?.alternativeText}
                        src={restaurant?.logo?.url || ""}
                        width={30}
                      />
                      <div className="media-body text-center">
                        <h6 className="mb-1 text-black capitalize">
                          {locationsContext.selectedLocation?.name}
                        </h6>
                        <p className="mb-0 text-black font-14">
                          {locationsContext.selectedLocation?.address?.address_1},{" "}
                          {locationsContext.selectedLocation?.address?.city}{" "}
                        </p>
                      </div>
                    </div>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        <hr className="my-0" />
      </Container>
      {/* CART MODAL */}
      <CartModal show={showCartModal} onHide={handleClose} />
      <DeliveryAddressModal show={showDeliveryModal} onHide={handleCloseDelivery} />
      <PickupAddressModal show={showPickup} onHide={handleClosePickup} />
    </>
  );
};

export default Header;
