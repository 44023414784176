import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import produce from "immer";
import _ from "lodash";
import countriesList from "../../utils/countriesList";
import { usePostalCodePattern } from "../../hooks";
import { defaultAttributesByCountry } from "./utils";
import { useTrans } from "../../hooks";

const AddressInput = ({ address, setAddress, uniqueKey, fieldAttributes }) => {
  const transMsg = useTrans();
  const postalCodePattern = usePostalCodePattern({ country: address.country });
  const countryDefaultAttributes =
    defaultAttributesByCountry[_.lowerCase(address?.country || "")];

  const onInputChange = ({ target: { type, name, value, checked } }) => {
    setAddress(
      produce(address, (draft) => {
        draft[name] = type === "checkbox" ? checked : value;
      }),
    );
  };

  const fields = useMemo(() => {
    let defaultAttributes = {
      firstName: {
        col: "6",
        required: true,
        display: true,
        defaultValue: "",
        type: "text",
        label: transMsg("firstName"),
      },
      lastName: {
        col: "6",
        required: true,
        display: true,
        defaultValue: "",
        type: "text",
        label: transMsg("lastName"),
      },
      address_1: {
        col: "12",
        required: true,
        display: true,
        defaultValue: "",
        type: "text",
        label: transMsg("address_1"),
      },
      address_2: {
        col: "12",
        required: false,
        display: true,
        defaultValue: "",
        type: "text",
        label: transMsg("address_2"),
      },
      city: {
        col: "6",
        required: true,
        display: true,
        defaultValue: "",
        type: "text",
        label: transMsg("city"),
      },
      postalCode: {
        col: "6",
        required: true,
        display: true,
        defaultValue: "",
        pattern: postalCodePattern?.pattern,
        formatExample: postalCodePattern?.formatExample,
        type: "text",
        label: transMsg("postalCode"),
      },
      province: countryDefaultAttributes?.province || {
        col: "6",
        required: true,
        display: true,
        defaultValue: "",
        type: "text",
        label: transMsg("province"),
      },
      country: {
        col: "6",
        required: true,
        display: true,
        defaultValue: "",
        type: "select",
        label: transMsg("country"),
      },
      phone: {
        col: "6",
        required: true,
        display: true,
        defaultValue: "",
        type: "text",
        label: transMsg("phone"),
      },
    };

    return _.keys(defaultAttributes).map((k) => {
      if (fieldAttributes && fieldAttributes[k])
        return {
          key: k,
          ...defaultAttributes[k],
          ...fieldAttributes[k],
        };

      return { ...defaultAttributes[k], key: k };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldAttributes, postalCodePattern, countryDefaultAttributes]);

  return (
    <>
      <div className="row">
        {fields.map((v, i) => {
          if (v.display)
            return (
              <React.Fragment key={i}>
                <div className={`col-md-${v.col}`}>
                  <div className="form-label-group">
                    {v.type === "select" ? (
                      <>
                        <Form.Control
                          as="select"
                          custom
                          className="mt-3 time-select"
                          required={v.required}
                          name={v.key}
                          onChange={(e) => {
                            const {
                              target: { type, name, value, checked },
                            } = e;
                            onInputChange({
                              target: {
                                type,
                                name: "countryCode",
                                value: countriesList[e.target.selectedIndex].code,
                                checked,
                              },
                            });

                            onInputChange({
                              target: {
                                type,
                                name,
                                value,
                                checked,
                              },
                            });
                          }}
                          value={address[v.key] || v.defaultValue}
                        >
                          {countriesList.map((c, cI) => {
                            return (
                              <React.Fragment key={cI}>
                                <option value={c.name}>{c.name}</option>
                              </React.Fragment>
                            );
                          })}
                        </Form.Control>
                      </>
                    ) : (
                      <>
                        <Form.Control
                          as="input"
                          type={v.type}
                          id={`${v.key}_${uniqueKey}`}
                          placeholder={v.placeholder || _.startCase(v.key)}
                          name={v.key}
                          required={v.required}
                          onChange={onInputChange}
                          pattern={v.pattern}
                          value={address[v.key] || v.defaultValue}
                        />

                        <Form.Label htmlFor={`${v.key}_${uniqueKey}`}>
                          {address[v.key] ? (
                            <>
                              {v.formatExample ? (
                                <span>
                                  {" "}
                                  {transMsg("format")}: {v.formatExample}
                                </span>
                              ) : (
                                <> {v.label}</>
                              )}
                              {v.required && <span className="text-danger pr-2">*</span>}
                            </>
                          ) : (
                            <>
                              {v.label}
                              {v.required && <span className="text-danger pr-2">*</span>}
                            </>
                          )}
                        </Form.Label>
                      </>
                    )}
                  </div>
                </div>
              </React.Fragment>
            );
          else return <React.Fragment key={i}></React.Fragment>;
        })}
      </div>
    </>
  );
};

export default AddressInput;
