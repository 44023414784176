/** This is a workaround until strapi provide an api for different image sizes */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

const STRAPI_IMAGE_SIZES = {
  SMALL: "small_",
  THUMBNAIL: "thumbnail_",
  MEDIUM: "medium_",
  LARGE: "large_",
  ORGINIAL: "",
};

const StrapiImage = ({ src, size, alt, ...rest }) => {
  const [modifySrc, setModifySrc] = useState("");

  useEffect(() => {
    updateSrc(size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const updateSrc = (modifySize) => {
    if (_.isEmpty(src)) return;

    const separator = "/";
    const srcArray = _.split(src, separator);

    const name = _.last(srcArray);
    const srcWithoutName = _.join(_.dropRight(srcArray), separator);

    setModifySrc(`${srcWithoutName}/${modifySize}${name}`);
  };

  const onError = () => {
    updateSrc("");
  };
  return (
    <>
      <img src={modifySrc} alt={alt || ""} {...rest} onError={onError} />
    </>
  );
};

StrapiImage.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.string,
};
StrapiImage.defaultProps = {
  size: STRAPI_IMAGE_SIZES.ORGINIAL,
};

export default StrapiImage;
export { STRAPI_IMAGE_SIZES };
