import _ from "lodash";

const formatAddress = (address, short = true, addAddress2 = true) => {
  let res = "";

  if (_.isEmpty(address)) return res;

  if (!_.isEmpty(address.address_2) && addAddress2) res += `${address.address_2} - `;

  if (!_.isEmpty(address.address_1)) res += `${address.address_1}, `;

  if (!_.isEmpty(address.city)) res += `${address.city}, `;

  if (!_.isEmpty(address.province) && !short) res += `${address.province}, `;

  if (!_.isEmpty(address.postalCode)) res += `${address.postalCode}`;

  if (!_.isEmpty(address.country) && !short) res += `, ${address.country}`;

  return res;
};

export default formatAddress;
