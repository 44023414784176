import React, { useContext } from "react";
import { Modal, NavDropdown } from "react-bootstrap";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { CartContext, LocationsContext } from "../../context";
import _ from "lodash";
import CheckoutItem from "../common/CheckoutItem";
import CartDropdownHeader from "../cart/CartDropdownHeader";
import { useBusinessIsOpen, usePriceFormat, useUpdateCartQty } from "../../hooks";
import CannotDeliverMessage from "../CannotDeliverMessage";
import { useTrans } from "../../hooks";

// =========================================================
// Define a cart modal component that displays a modal window containing the items in the user's cart
// along with the total price of the items in the cart and if applicable, the delivery fees
// the component takes the following props:
// show: a boolean value that determines if the modal should be displayed
// onHide: specifies a function to call when the modal is closed
// the component returns jsx that defines the layout and content of the modal
const CartModal = ({ show, onHide }) => {
  // use the translation hook to provide internationalization support
  const transMsg = useTrans();
  // use the context hook to access the cart state and information about the selected location
  const { cartLineItems, cartTotals, orderType, scheduleTime } = useContext(CartContext);
  const locationsContext = useContext(LocationsContext);

  // use the usePriceFormat hook and the useUpdateCartQty hook to format the price and update the quantity of items in the cart
  const { priceFormat, priceSymbol } = usePriceFormat();
  const getQty = useUpdateCartQty();

  const { isOpen, isScheduleNow } = useBusinessIsOpen();

  // =========================================================
  /**
   * When the modal is displayed, it first shows a message if the selected location cannot deliver to the user's location.
   * Then, it displays the name and address of the selected location,
   * followed by a list of the items in the user's cart.
   * For each item, the component displays the item name, price, and quantity, and allows the user to update the quantity of the item using a provided input field.
   * After the list of items, the component displays the total price of the items,
   * followed by the delivery fees (if applicable) and the grand total.
   * Finally, the component includes a "Checkout" button that, when clicked, would take the user to the checkout page to complete their purchase.
   */
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton className="ml-2">
          <Modal.Title>{transMsg("yourOrder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CannotDeliverMessage />
          <div className="dropdown-cart-top">
            {
              <CartDropdownHeader
                orderTitle=""
                className="dropdown-cart-top-header p-4"
                title={locationsContext?.selectedLocation?.name || ""}
                subTitle={
                  locationsContext?.selectedLocation?.address?.address_1 +
                  ", " +
                  locationsContext?.selectedLocation?.address?.city
                }
                NavLinkUrl="#"
                NavLinkText={transMsg("viewFullMenu")}
              />
            }
            <div className="dropdown-cart-top-body border-top p-4 ">
              {_.isEmpty(cartLineItems) ? (
                <p className="text-14 text-grey">{transMsg("emptyCartMessage")}</p>
              ) : null}

              {!_.isEmpty(cartLineItems) &&
                _.map(cartLineItems, (v, k) => {
                  return (
                    <CheckoutItem
                      key={k}
                      itemName={v.title}
                      price={v.unitPrice}
                      priceUnit={priceSymbol}
                      id={v.id}
                      quantity={v.quantity}
                      show={true}
                      minValue={0}
                      maxValue={99}
                      getValue={getQty}
                      choices={v.choices}
                      note={v.note}
                    />
                  );
                })}

              <hr />

              <div className="row">
                <div className="col-6">{transMsg("itemTotal")}</div>
                <div className="col-6 text-right">{priceFormat(cartTotals?.subtotal)}</div>
              </div>
              {orderType === "delivery" && !_.isUndefined(cartTotals.deliveryFees) && (
                <>
                  <div className="row mt-2">
                    <div className="col-6">{transMsg("delivery")}</div>
                    <div className="col-6 text-right">
                      {priceFormat(cartTotals.deliveryFees)}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {!_.isEmpty(cartLineItems) && (
            <div className="dropdown-cart-top-footer border-topOFF p-2OFF">
              <NavDropdown.Item
                eventKey={5.1}
                as={Link}
                disabled={!isOpen && isScheduleNow(scheduleTime.from)}
                className="btn btn-warning btn-block py-3 text-dark text-center dropdown-item btn-checkout bold"
                to="/checkout"
              >
                <div style={{ height: "20px" }}>
                  <span className="text-center">{transMsg("checkout")}</span>
                </div>
              </NavDropdown.Item>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CartModal;
