import React, { useContext, useEffect, useState } from "react";
import { LocationsContext, MaintenanceContext } from "../../context";
import { Modal, Button } from "react-bootstrap";
import { useTrans } from "../../hooks";

const MaintenanceModal = () => {
  const transMsg = useTrans();
  const [isOpen, setIsOpen] = useState(false);

  const { isUnderMaintenance } = useContext(MaintenanceContext);

  const onHide = () => {
    setIsOpen(!isOpen);
  };

  const { selectedLocation } = useContext(LocationsContext);
  useEffect(() => {
    setIsOpen(isUnderMaintenance);
  }, [isUnderMaintenance]);

  return (
    <Modal show={isOpen} onHide={onHide} centered>
      <Modal.Header closeButton={true}>
        <Modal.Title as="h5" id="edit-profile">
          {transMsg("underMaintenance")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>{transMsg("underMaintenanceContent1")}</div>

        <div className="mt-2">{transMsg("underMaintenanceContent2")}</div>
        <br />

        <a className=" ml-1 " href={`tel:${selectedLocation?.address?.phone}`}>
          {selectedLocation?.address?.phone}
        </a>
      </Modal.Body>

      <Modal.Footer>
        <div className="row">
          <div className="col-6">
            <Button type="button" onClick={onHide} variant="outline-dark">
              {transMsg("cancel")}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MaintenanceModal;
